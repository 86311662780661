export const cs = {
    translation: {
        general: {
            bookNow: 'Rezervovat',
            bookNowFor: 'Rezervovat za',
            czk: 'Kč',
            occupied: 'Garáž plná',
            occupiedShort: 'Plná',
            min: 'Min.',
            hours: 'hod.',
            hoursShortcut: 'hod',
            list: 'Seznam',
            map: 'Mapa',
            filters: 'Filtry',
            hide: 'Skrýt',
            garagesFound_one: 'Nalezena <strong>{{count}} garáž</strong>',
            garagesFound_few: 'Nalezeny <strong>{{count}} garáže</strong>',
            garagesFound_many: 'Nalezeno <strong>{{count}} garáží</strong>',
            garagesFound_other: 'Nalezeno <strong>{{count}} garáží</strong>',
            garagesAvailableLater_one: 'Zdá se, že v tomto čase je hodně garáží obsazených. Posuňte začátek rezervace na <strong>18:00</strong> a bude dostupných o <strong>{{count}} garáž více</strong>.',
            garagesAvailableLater_few: 'Zdá se, že v tomto čase je hodně garáží obsazených. Posuňte začátek rezervace na <strong>18:00</strong> a bude dostupných o <strong>{{count}} garáže více</strong>.',
            garagesAvailableLater_many: 'Zdá se, že v tomto čase je hodně garáží obsazených. Posuňte začátek rezervace na <strong>18:00</strong> a bude dostupných o <strong>{{count}} garáží více</strong>.',
            garagesAvailableLater_other: 'Zdá se, že v tomto čase je hodně garáží obsazených. Posuňte začátek rezervace na <strong>18:00</strong> a bude dostupných o <strong>{{count}} garáží více</strong>.',
            moveToEighteen: 'Posunout rezervaci na 18:00',
            close: 'Zavřít',
            availableFromEighteen: 'Dostupná od 18:00',
            fromEighteen: 'Od 18:00',
            lastPlacesAvailable: 'Poslední místa',
            boolean: {
                true: 'Ano',
                false: 'Ne'
            },
            phoneInput: { // intl-tel-input
                searchPlaceholder: "Zvolte předvolbu",
            }
        },
        filters: {
            showAvailable: 'Zobrazit pouze dostupné garáže',
            yesShow: 'Ano',
            priceRange: 'Cenové rozpětí',
            carHeight: 'Výška vašeho vozidla',
            sortBy: 'Řadit dle',
            distance: 'Vzdálenost',
            price: 'Cena',
            remove: 'Zrušit všechny filtry',
            reset: 'Resetovat filtry',
            showResults: 'Zobrazit výsledky',
            noResultsMatch: 'Zadaným parametrům neodpovídají žádné garáže.',
        },
        search: {
            reset: 'Resetovat',
            edit: 'Upravit',
            search: 'Hledat',
            where: 'Kde chcete zaparkovat?',
            wherePlaceholder: 'Město, ulice, místo...',
            whereEmpty: 'Žádné návrhy',
            start: 'Začátek',
            end: 'Konec',
            reservationStart: 'Začátek rezervace',
            reservationEnd: 'Konec rezervace',
            reservationLength: 'Délka rezervace',
            reservationExtendedLength: 'Délka prodloužené rezervace',
            reservationDate: 'Termín rezervace',
            days_one: ' {{count}} den',
            days_few: ' {{count}} dny',
            days_many: ' {{count}} dní',
            days_other: ' {{count}} dní',
            hours_one: ' {{count}} hodina',
            hours_few: ' {{count}} hodiny',
            hours_many: ' {{count}} hodin',
            hours_other: ' {{count}} hodin',
            minutes_one: ' {{count}} minuta',
            minutes_few: ' {{count}} minut',
            minutes_many: ' {{count}} minut',
            minutes_other: ' {{count}} minut',
            selectStart: 'Vyberte v kalendáři datum začátku rezervace',
            selectEnd: 'Vyberte v kalendáři datum konce rezervace',
            startInThePast: 'Začátek vaší rezervace je v minulosti!',
            reservationTooShort: 'Rezervace musí mít minimálně 6 hodin.',
            defaultLocation: 'Parkování v České republice',
            selectExtensionStart: 'Vyberte v kalendáři datum nového ZAČÁTKU rezervace (případně zvolte stejný den).',
            selectExtensionEnd: 'Vyberte v kalendáři datum nového KONCE rezervace (případně zvolte stejný den).',
            extensionStartsTooLate: 'Zvolený začátek prodloužení nesmí být později než začátek rezervace.',
            extensionEndsTooEarly: 'Zvolený konec prodloužení nesmí být dříve než konec rezervace.',
            extensionIntoThePast: 'Rezervaci nelze prodloužit do minulosti.',
            checkAvailability: 'Zjistit dostupnost',
            sendExtensionRequest: 'Odeslat žádost o prodloužení',
            return: 'Zpět',
            extendReservation: 'Prodloužit rezervaci',
            extensionPossible: 'Vaši rezervaci je možné prodloužit!',
            extensionPrice: 'Cena prodloužení: {{price}} CZK',
            payToContinue: 'Pro potvrzení prodloužení prosím proveďte úhradu rozdílu.',
            toPaymentCTA: 'Uhradit',
        },
        routes: {
            garages: 'garaze',
            reservations: 'rezervace',
            reservations_steps: {
                reservation_time: 'cas-rezervace',
            },
            users: 'uzivatele',
            signIn: 'prihlaseni',
            register: 'registrace',
            signOut: 'odhlasit',
        },
        reservations: {
            status: {
                booked: 'Budoucí',
                current: 'Probíhající',
                elapsed: 'Již proběhlá',
                cancelled: 'Zrušená',
                allocated: 'Alokovaná',
            },
            general: {
                active: 'Probíhající rezervace',
                all: 'Všechny rezervace',
                noBooked: 'Nemáte žádnou probíhající ani budoucí rezervaci.',
                noBookedCreate: 'Vytvořit rezervaci',
                remaining: 'Zbývá',
            },
            filters: {
                sortBy: 'Řazení',
                id: 'ID',
                startAt: 'Začátek rezervace',
                status: 'Filtr stavu',
                all: 'Všechny',
                active: 'Probíhající',
                elapsed: 'Proběhlé',
                cancelled: 'Zrušené',
                allocated: 'Alokované',
            },
            table: {
                reservation: 'Rezervace',
                garage: 'Garáž',
                unit: 'Místo',
                driver: 'Řidič',
                licensePlate: 'SPZ',
                phone: 'Telefon',
                date: 'Termín',
                price: 'Cena',
                createdAt: 'Vytvořeno',
                garageAndUnit: 'Garáž a místo',
                from: 'Od',
                to: 'Do',
                phoneNumbers: 'Tel. čísla',
                cancelReservation: 'Stornovat',
                editDetails: 'Upravit SPZ/telefon',
                bookNextDay: 'Zopakovat rezervaci další den',
                bookTomorrow: 'Zopakovat rezervaci od zítra',
                bookAgain: 'Vytvořit rezervaci ve stejné garáži',
                unpaidDifference: 'Uhraďte prosím {{price}} {{currency}} pro dokončení prodloužení.',
                changeUnitApprove: 'Pro dokončení změny parkovacího místa prosím potvrďte provozní řád garáže.',
                confirm: 'Potvrdit',
                openGarage: 'Detail garáže'
            },
            documents: {
                tickets: 'Parkovací lístky',
                invoices: 'Faktury',
                invoiceCorrections: 'Opravné doklady',
                creditNotes: 'Vratky',
                other: 'Další dokumenty',
                termsOfService: 'Podmínky poskytování služeb',
                privacyPolicy: 'Zásady ochrany os. údajů',
                garageOperatingTerms: 'Provozní řád garáže',
            },
            active: {
                open: 'Otevřít',
                entrance: 'vjezd',
                exit: 'výjezd',
                reception: 'recepci',
                extend: 'Prodloužit',
                payExtension: 'Uhradit rozdíl',
                cancel: 'Zrušit',
                confirm: 'Potvrdit',
                ending: 'Končí',
                starting: 'Začíná',
                ended: 'Skončila',
                cancelNotPossible: 'Vaši rezervaci není možné stornovat:',
                cancelSuccessful: 'Vaše rezervace byla úspěšně stornována.',
            },
            edit: {
                save: 'Uložit',
                cancel: 'Zrušit',
                phoneNumber1: 'Tel. číslo 1',
                phoneNumber2: 'Tel. číslo 2',
                licensePlate: 'SPZ',
            },
            cancel: {
                confirmation: 'Chcete stornovat tuto rezervaci?',
                detail: 'Po zrušení rezervace ji již nebude možné obnovit.',
                cancel: 'Stornovat rezervaci',
                keep: 'Zachovat',
            },
            empty: {
                title: 'Zatím jste neprovedli žádnou rezervaci',
                text: 'Vytvořte novou rezervaci. Všechny Vaše rezervace si pak budete moci prohlédnout přehledně zde.'
            },
        },
        profile: {
            refetch: 'Načíst znovu',
            languageSelector: 'Výběr jazyka',
            currencySelector: 'Výběr měny',
            signOut: 'Odhlásit se',
            userProfile: {
                title: "Profil uživatele",
                email: "Email",
                userName: "Jméno a příjmení",
                phoneNumber1: "Telefon č.1",
                phoneNumber1Tooltip: "Slouží k otevření garáže",
                phoneNumber2: "Telefon č.2",
                agreedToMarketing: "Souhlas s marketingem",
                edit_agreedToMarketing: "Povolit marketing",
            },
            invoiceDetails: {
                title: "Fakturační údaje",
                edit_title: "Upravit fakturační údaje",
                edit_save: "Upravit",
                empty: "Zatím nepřidáno",
                invoice_title: "Název firmy",
                invoice_street: "Ulice",
                invoice_city: "Město",
                invoice_zip_code: "PSČ",
                invoice_country: "Země",
                invoice_ic: "IČ",
                invoice_dic: "DIČ",
            },
            cars: {
                title: "Vozidla",
                edit_title: "Upravit vozidlo",
                edit_save: "Upravit",
                add_title: "Přidat vozidlo",
                add_confirm: "Přidat",
                name: "Název",
                numberPlate: "SPZ",
                color: "Barva",
                add: "Přidat"
            }
        },
        success: {
            congratulations: {
                title: 'Úspěšná rezervace! Děkujeme.',
                imageAlt: 'Ilustrační obrázek rezervace',
                text: 'Na uvedený e-mail jsme odeslali parkovací lístek s detaily Vaší rezervace. Pro Vaše pohodlí jsme Vám rovněž poslali vše v SMS na vámi uvedená telefonní čísla. Parkovací místo musí být uvolněno nejpozději do skončení rezervace!',
                myReservationsLink: 'Pokračovat do moje rezervace',
            },
            summary: { 
                title: 'Detail rezervace',
            },
            reservationSummary: {
                totalPrice: 'Celkem:',           
                email: 'E-mail',
                name: 'Jméno a příjmení',
                numberPlate: 'SPZ',
                carColor: 'Barva vozu',
                floorWithNumber:'Patro / Parkovací místo',
                phoneNumber:'Telefon č.1',
                phoneNumber2:'Telefon č.2',
            }
        },
        garage: {
            car_dimensions: {
                title: 'Maximální rozměry',
                height: 'Výška',
                length: 'Délka',
                width: 'Šířka',
            },
            showLess: 'Zobrazit méně',
            showMore: 'Zobrazit více',
            mainBenefits: {
                first: 'Opakovaný vjezd i výjezd',
                second: 'Instrukce v e-mailu a SMS',
                third: 'Snadné storno i změna rezervace',
            },
            openOnMap: "Navigovat",
            benefits: {
                title: 'Parametry',
                list: {
                    '10_minutes_form_city_center': 'Centrum města do 10 min',
                    '15_minutes_from_city_center': 'Centrum města do 15 min',
                    '5_minutes_form_city_center': 'Centrum města do 5 min',
                    call_reception: 'Po příjezdu prosím zavolejte na recepci.',
                    cctv: 'Kamerový systém',
                    cctv_at_entrance: 'Kamerový systém u vjezdu',
                    city_center: 'Centrum města ',
                    historic_city_center: 'Historické centrum města',
                    charging_station: 'Dobíjení pro elektromobily',
                    licence_plate_validation: 'Otevření závory čtením SPZ',
                    limited_vehicle_dimensions: 'Omezené rozměry vozu!',
                    main_reception:
                        'Pristup do garaze ziskate na hlavni recepci budovy ve vstupni hale - az pote lze vjet do garaze.',
                    nonstop_operation: 'Non-stop provoz',
                    nonstop_reception: 'Non-stop recepce',
                    nonstop_service: 'Non-stop provoz',
                    number_plate_recognition: 'Kamera přečte Vaši SPZ a otevře závoru',
                    open_gate_by_attendant: 'Vrata garáže Vám otevře recepční',
                    open_gate_by_phone: 'Vrata garáže otevíráte svým telefonem',
                    parking_for_disabled: 'Kamerový systém u vjezdu',
                    security_system: 'Bezpečnostní systém',
                    subway_availability: 'Tramvaj v blízkosti',
                    tram_availability: 'Metro v blízkosti',
                    wc: 'WC',
                },
            },
            prohibitions: {
                title: 'Zákazy',
                list: {
                    lpg_cng_not_allowed: 'LPG a CNG není povoleno',
                    electric_car_not_allowed: 'Zákaz parkování elektromobilů',
                    motorbikes_not_allowed: 'Zákaz parkování motorek',
                },
            },
            garage_videos: {
                title: 'Videogalerie',
            },
            confirmationModal: {
                maxDimensionsTitle: 'Upozornění',
                cancel: 'Zrušit',
                complete: 'Rezervovat',
            }
        },
        loginCrossroads: {
            login: "Přihlásit se",
            createAccount: "Vytvořit účet",
            skip: "Nyní přeskočit",
        },
        pwa: {
            search: 'Hledat',
            myReservations: 'Moje rezervace',
            profile: 'Profil',
            myReservationsNoUser: 'Pro správu rezervací a otevírání garáží je třeba se přihlásit:',
            profileNoUser: 'Pro správu profilu, vozidel a fakturace je třeba se přihlásit:'
        },
    },
}